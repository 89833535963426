// ページ訪問時ランダムにクラス名付与
// $(function () {
//   var num = Math.ceil(2 * Math.random());
//   if (num == 1) {
//     $('body').addClass('site-mode2');
//   }
// });

// 年数（コピーライト）
$(function(){
  var year = new Date().getFullYear();
  $("#year").html(year);
  console.log(year);
});

// 色変換ボタン
$(function() {
  $('.fluid-btn').click(function () {
    $('body').toggleClass('site-mode2');
  });
});

// リンク先へ遷移する際のスクロール
$(function() {
  var speed = 500;
  var type = 'swing';

  $('a[href^="#"]').on('click', function() {
    var href = $(this).attr("href");
    var target = $(href == "#" || href == "" ? 'html' : href);
    var adjust = 0;
    var position = target.offset().top + adjust;
    $('html, body').animate(
      {scrollTop:position},
      speed,
      type
    );
    return false;
  });

  //現在のページURLのハッシュ部分を取得
  const hash = location.hash;
  
  //ハッシュ部分がある場合の条件分岐
  if(hash){
    //ページ遷移後のスクロール位置指定
    $("html, body").stop().scrollTop(0);
  
    //処理を遅らせる
    setTimeout(function(){
      //リンク先を取得
      const target = $(hash),
  
      //リンク先までの距離を取得
      position = target.offset().top;
  
      //指定の場所までスムーススクロール
      $("html, body").animate(
        {scrollTop:position},
        speed,
        type
        );
    });
  }
});


// マウスカーソル
const linkElem = document.querySelectorAll('a:not(.no_stick_),input[type="submit"],input[type="button"],.cookie-agree');
for (let i = 0; i < linkElem.length; i++) {
  linkElem[i].addEventListener('mouseover', function (e) {
    let rect = e.target.getBoundingClientRect();
    let posX = rect.left + (rect.width / 2);
    let posY = rect.top + (rect.height / 2);
  });
  linkElem[i].addEventListener('mouseout', function (e) {
    hovFlag = false;
  });
}

let cursorR = 4;
const cursor = document.getElementById('cursor'); 
document.addEventListener('mousemove', function (e) {
  cursor.style.transform = 'translate(' + e.clientX + 'px, ' + e.clientY + 'px)';
});
const linkElem2 = document.querySelectorAll('a,button');
for (let i = 0; i < linkElem.length; i++) {
  linkElem[i].addEventListener('mouseover', function (e) {
    cursor.classList.add('hov_');
  });
  linkElem[i].addEventListener('mouseout', function (e) {
    cursor.classList.remove('hov_');      
  });
}


// 動きのきっかけの起点となるアニメーションの名前を定義
function fadeAnime(){
  // ふわっ（上から）
  $('.fadeDownTrigger').each(function(){ //fadeDownTriggerというクラス名が
    var elemPos = $(this).offset().top-50;//要素より、50px上の
    var scroll = $(window).scrollTop();
    var windowHeight = $(window).height();
    if (scroll >= elemPos - windowHeight) {
      $(this).addClass('fadeDown');// 画面内に入ったらfadeDownというクラス名を追記
    } else {
      $(this).removeClass('fadeDown');// 画面外に出たらfadeDownというクラス名を外す
    }
  });
  
  // にゅーん（滑らかに変形して出現）	
  $('.smoothTrigger').each(function(){ //smoothTriggerというクラス名が
    var elemPos = $(this).offset().top-50;//要素より、50px上の
    var scroll = $(window).scrollTop();
    var windowHeight = $(window).height();
    if (scroll >= elemPos - windowHeight) {
      $(this).addClass('smooth');// 画面内に入ったらsmoothというクラス名を追記
    } else {
      $(this).removeClass('smooth');// 画面外に出たらsmoothというクラス名を外す
    }
  });
}

$(window).on('load', function () {
  fadeAnime();
});

$(window).scroll(function () {
  fadeAnime();
});

// クリックしたら円形背景が拡大
$(".openbtn").click(function () {
	$(this).toggleClass('active');
  $("#g-nav").toggleClass('panelactive');
  $(".circle-bg").toggleClass('circleactive');
});

$("#g-nav a").click(function () {
  $(".openbtn").removeClass('active');
  $("#g-nav").removeClass('panelactive');
  $(".circle-bg").removeClass('circleactive');
});

// クッキー
$(document).ready(function() {
  const a = "cookieAgreeJP";
  let s = document.querySelector(".cookie-consent"),
    l = document.querySelector(".cookie-agree");
  const d = localStorage.getItem(a);
  if (null != d) {
  const e = JSON.parse(d);
  if ("true" == e.value) setTimeout(c, 1e3);
  else {
    (new Date).getTime() > e.expire && (r(a, "true", 365), setTimeout(c, 1e3))
  }
  } else r(a, "true", 365), setTimeout(c, 1e3);

  function r(e, t, i) {
  const n = {
    value: t,
    expire: i = (new Date).getTime() + 24 * i * 3600 * 1e3
  };
  localStorage.setItem(e, JSON.stringify(n))
  }
  function c() {
  s.classList.add("is-show")
  }
  l.addEventListener("click", () => {
		s.classList.remove("is-show"), r(a, "false", 365)
	})
});
